import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { IoSave, IoTrash } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import { LoadingSpinner } from "components/loadingSpinner";

import { extractMulti, languages } from "helpers/ui/multiLang";
import { Modal } from "components/modal";
import { IconButton } from "components/button";

import { Item } from "./table";
import { Link } from "wouter";

import { category_query } from "queries/referenceData";

export const EditItemModal: React.FC<{
  item: Item;
  close: () => void;
  actions: any;
}> = ({ close, item, actions }) => {
  const { t } = useTranslation([
    "reference-data",
    "common",
    "mainLanguages",
    "employee",
  ]);
  const [newItemName, setNewItemName] = useState(item?.name ?? {});
  const isNew = item.id === "new";
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const category_assoc = {
    SkillAssoc: "skill",
    CompetenceAssoc: "competence",
    DiplomaAssoc: "diploma",
    RoleAssoc: "role",
    LanguageAssoc: "language",
    ReferenceRoleAssoc: "referenceRole",
  };

  const category = category_assoc[item.__typename ?? ""];
  const query = category_query(category);

  // Fetch employees
  const { data } = useQuery(query, {
    skip: !category,
    variables: { id: item.id },
  });

  // Use Set to remove duplicates
  const sortedEmployees =
    category === "referenceRole"
      ? [
          ...new Set(
            data?.[category]?.references
              ?.slice()
              ?.map((reference) => reference.employee)
              ?.filter((employee) => !!employee)
              ?.sort((a, b) => {
                const aName = a?.lastName ?? "";
                const bName = b?.lastName ?? "";
                return aName < bName ? -1 : aName > bName ? 1 : 0;
              }) ?? [],
          ),
        ]
      : data?.[category]?.employees?.slice()?.sort((a, b) => {
          const aName = a.lastName;
          const bName = b.lastName;
          return aName < bName ? -1 : aName > bName ? 1 : 0;
        });

  return (
    <Modal
      actions={
        <div className="flex justify-between w-full">
          <div className="px-6">
            {!isNew ? (
              <IconButton
                type="warning"
                Icon={IoTrash}
                onClick={() => {
                  setShowConfirmDelete(true);
                }}
              >
                {t("common:delete")}
              </IconButton>
            ) : null}
          </div>
          <div className="flex items-center px-6 space-x-2">
            <IconButton type="secondary" Icon={IoMdClose} onClick={close}>
              {t("common:close")}
            </IconButton>
            <IconButton
              Icon={IoSave}
              onClick={() => {
                if (isNew) {
                  actions.create(newItemName);
                } else {
                  actions.update(item.id, extractMulti(newItemName));
                }
                close();
              }}
            >
              {t("common:save")}
            </IconButton>
          </div>
          {/* Confirm delete */}
          <Modal
            size="small"
            title={
              <div className="px-6 py-8 text-2xl font-medium text-red-500">
                {t("reference-data:confirmDelete")}
              </div>
            }
            open={showConfirmDelete}
            close={() => setShowConfirmDelete(false)}
            actions={
              <div className="flex flex-row space-x-2">
                <IconButton
                  type="secondary"
                  Icon={IoMdClose}
                  onClick={() => setShowConfirmDelete(false)}
                >
                  {t("common:cancel")}
                </IconButton>
                <IconButton
                  type="warning"
                  Icon={IoTrash}
                  onClick={() => {
                    actions.delete(item.id);
                    close();
                  }}
                >
                  {t("common:delete")}
                </IconButton>
              </div>
            }
          >
            <div className="px-6 my-8">
              {t("reference-data:confirmDeleteTxt")}
            </div>
          </Modal>
        </div>
      }
      open={true}
      close={close}
      title={
        <div className="px-6 py-8 text-2xl font-medium text-red-500">
          {isNew ? t("reference-data:create") : t("common:edit")}
        </div>
      }
    >
      <div
        className="grid gap-4 px-6 my-8"
        style={{ gridTemplateColumns: "1fr 3fr" }}
      >
        {languages.map((lang) => (
          <React.Fragment key={lang}>
            <div className="flex flex-col justify-between break-word">
              <div className="font-medium">
                {t(`mainLanguages:${lang}` as any)}
              </div>
            </div>
            <input
              type="text"
              className="block w-full p-2 border rounded-md shadow-sm outline-none border-grey-300 focus:ring-grey-900 focus:ring-2 focus:border-grey-900 sm:text-sm"
              value={newItemName[lang] ?? ""}
              onChange={(e) =>
                setNewItemName({
                  ...newItemName,
                  [lang]: e.currentTarget.value,
                })
              }
            />
          </React.Fragment>
        ))}
      </div>
      {category ? (
        <div className="px-6 my-8">
          <div className="mb-3">
            {t("employee:usedBy", { num: `${sortedEmployees.length ?? ""}` })}
          </div>
          {sortedEmployees ? (
            sortedEmployees.map((employee) => {
              return (
                <div key={employee.id} className="flex mb-2">
                  <span className="text-black cursor-pointer hover:text-blue-500">
                    <Link href={`/employee/${employee?.id}`}>
                      — {employee.firstName} {employee.lastName}
                    </Link>
                  </span>
                </div>
              );
            })
          ) : (
            <LoadingSpinner />
          )}
        </div>
      ) : null}
    </Modal>
  );
};
